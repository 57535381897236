import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Alert,
} from "@mui/material";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/authContext";
import CalculateDuration from "../../helpers/CalculateDuration";
import FormatDateToFrench from "../../helpers/FormateDateToFrensh";
import {
  AttacheServicesToBoking,
  CheckTheAvailabilty,
  CreateAvailability,
  CreateBooking,
  CreatePayment,
  FetchData,
  RegisterGuest,
  SendEmail,
} from "../../actions/data";
import { loginClient, registerClient } from "../../actions/auth";
import { toast, Toaster } from "react-hot-toast";
import { BOOKINGS, INIT_BOOKING } from "../../constants/actions";
import { Lg1 } from "../../assets/images";
import especs from "../../assets/images/payment/de-largent.png";
import paypal from "../../assets/images/payment/paypal.png";
import card from "../../assets/images/payment/credit-card-icons.png";
import PaymentsIcon from "@mui/icons-material/Payments";
import { apiClient } from "../../actions/api";
import { NapsPayment } from "../../components";
import { XCircle } from "lucide-react";
import PaymentSuccessModal from "../successpayment";

const LoadingSpinner = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-orange-600"></div>
  </div>
);

const LoadingOverlay = ({ message }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center gap-4">
      <LoadingSpinner />
      <p className="text-gray-700">{message}</p>
    </div>
  </div>
);

function CheckoutPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingState, setLoadingState] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [mode, setMode] = useState(0);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [venueMarketingConsent, setVenueMarketingConsent] = useState(false);
  const reservation = useSelector((state) => state?.reservation);
  const client = useSelector((state) => state?.auth?.client);
  const [formData, setFormData] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalTotalPrice, setfinalTotalPrice] = useState(0);
  const { isClientAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [showNapsPaymentDetails, setShowNapsPaymentDetails] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [giftCode, setGiftCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isGiftCodeValid, setIsGiftCodeValid] = useState(false);
  const [isGiftCodeSubmitted, setIsGiftCodeSubmitted] = useState(false);
  const [isApplyingCode, setIsApplyingCode] = useState(false);

  // check black listed 
  useEffect(() => {
      let formData = {
        ip_address: client?.ip_address ? client.ip_address : "",
        email: client?.email ? client.email : "",
      };

      try {
        const response = apiClient().post(`/check-blacklist`, formData);

        if (response.data?.success) {
          localStorage.setItem("blacklist", true);
          toast.error(response.data.message);
          return; // Exit the handleSubmit function
        } else {
          localStorage.removeItem("blacklist");
        }
      } catch (error) {
        console.error("Error checking blacklist:", error);
        toast.error("An error occurred while checking the blacklist.");
      }

  }, [])

  // Handle gift code input change
  const handleGiftCodeChange = (e) => {
    if (!isGiftCodeSubmitted) {
      setGiftCode(e.target.value);
    }
  };

  // Apply gift code
  const applyGiftCode = async () => {
    if (!giftCode.trim()) {
      toast.error("Veuillez entrer un code cadeau");
      return;
    }

    setIsApplyingCode(true);
    try {
      const response = await apiClient().post("/gift-codes/validate", {
        code: giftCode,
      });

      if (response.data.valid) {
        setDiscount(response.data.discount);
        setIsGiftCodeValid(true);
        setIsGiftCodeSubmitted(true);
        toast.success("Code cadeau appliqué avec succès !");

        // Update final total price
        const newFinalTotal = (
          totalPrice *
          (1 - response.data.discount / 100)
        ).toFixed(2);
        setfinalTotalPrice(newFinalTotal);
      } else {
        setIsGiftCodeValid(false);
        toast.error("Code cadeau invalide ou expiré.");
      }
    } catch (error) {
      console.error("Error applying gift code:", error);
      toast.error(
        "Une erreur est survenue lors de la validation du code cadeau."
      );
    } finally {
      setIsApplyingCode(false);
    }
  };

  // Remove gift code
  const removeGiftCode = () => {
    setGiftCode("");
    setDiscount(0);
    setIsGiftCodeValid(false);
    setIsGiftCodeSubmitted(false);
    setfinalTotalPrice(totalPrice);
    toast.success("Code cadeau retiré");
  };

  // Update final total price when discount changes
  useEffect(() => {
    if (totalPrice) {
      const finalTotal = (totalPrice * (1 - discount / 100)).toFixed(2);
      setfinalTotalPrice(finalTotal);
    }
  }, [totalPrice, discount]);

  useEffect(() => {
    if (
      !reservation?.salon ||
      !reservation?.services?.length ||
      !reservation?.date ||
      !reservation?.start_time ||
      !reservation?.end_time
    ) {
      navigate("/availability");
      return;
    }
  }, [reservation]);

  useEffect(() => {
    isClientAuthenticated ? setMode(0) : setMode(1);
  }, []);

  useEffect(() => {
    if (reservation?.services?.length) {
      const total = reservation?.services?.reduce(
        (sum, service) => sum + parseFloat(service?.pivot?.price),
        0
      );
      setTotalPrice(total.toFixed(2));
    }
  }, [reservation]);

  useEffect(() => {
    const finalTotalPrice = (totalPrice * (1 - discount / 100)).toFixed(2);
    setfinalTotalPrice(finalTotalPrice);
  }, [totalPrice, discount, isGiftCodeValid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSwitchMode = (mode) => {
    setMode(mode);
    setFormData();
  };

  const duration =
    reservation?.start_time &&
    reservation?.end_time &&
    CalculateDuration(reservation?.start_time, reservation?.end_time);

  const deductFromWallet = async (userId, amount) => {
    try {
      const response = await apiClient().post(`/wallet/deduct`, {
        user_id: userId,
        amount: amount,
      });
      return response.data;
    } catch (error) {
      console.error("Error deducting from wallet:", error);
      throw error;
    }
  };

  const fetchWalletBalance = async (userId) => {
    try {
      const response = await apiClient().get(`/user-balance/${userId}/balance`);
      return response.data.balance;
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      throw error;
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Check if the user has accepted the terms
  //   if (!venueMarketingConsent || !marketingConsent) {
  //     return toast.error("Vous devez accepter les conditions !");
  //   }

  //   setLoadingState("Vérification de la disponibilité...");

  //   const result = await dispatch(
  //     CheckTheAvailabilty({
  //       employee_id: reservation?.employee_id,
  //       date: reservation?.date,
  //       start_time: reservation?.start_time,
  //       end_time: reservation?.end_time,
  //     })
  //   ).then((response) => response);

  //   if (result) {
  //     setLoadingState("");
  //     setIsSubmitting(false);
  //     return toast.error(
  //       "Cette session est déjà réservée. Veuillez sélectionner une autre session."
  //     );
  //   }

  //   if (paymentMethod === "WALLET") {
  //     setLoading(true);
  //     setLoadingState("Vérification du solde du portefeuille...");

  //     try {
  //       // Fetch the user's wallet balance
  //       const walletBalance = await fetchWalletBalance(client?.id);

  //       // Check if the wallet has sufficient balance
  //       if (walletBalance < totalPrice) {
  //         setLoading(false);
  //         setLoadingState("");
  //         return toast.error("Solde insuffisant dans le portefeuille.");
  //       }

  //       // Deduct the amount from the wallet
  //       await deductFromWallet(client?.id, totalPrice);

  //       // Proceed with booking creation
  //       setIsSubmitting(true);
  //       setLoadingState("Création de votre réservation...");

  //       const availability = await dispatch(
  //         CreateAvailability({
  //           salon_id: reservation?.salon?.id,
  //           date: reservation?.date,
  //           start_time: reservation?.start_time,
  //           end_time: reservation?.end_time,
  //           employee_id: reservation?.employee_id,
  //         })
  //       ).then((response) => response);

  //       const booking = await dispatch(
  //         CreateBooking({
  //           user_id: client?.id,
  //           guest_id: null,
  //           salon_id: reservation?.salon?.id,
  //           availability_id: availability?.id,
  //           note: formData?.appointmentNotes,
  //         })
  //       ).then((response) => response);

  //       await dispatch(
  //         AttacheServicesToBoking(booking?.id, reservation?.services)
  //       );

  //       const payment = await dispatch(
  //         CreatePayment({
  //           booking_id: booking?.id,
  //           amount: totalPrice,
  //           payment_method: "WALLET",
  //           transaction_id: "WALLET_TRANSACTION",
  //         })
  //       ).then((response) => response);

  //       setLoadingState("Envoi de la confirmation...");

  //       const responseEmail = await dispatch(
  //         SendEmail({
  //           type: "reservation",
  //           name: client?.first_name,
  //           email: client?.email,
  //           services: reservation?.services
  //             ?.map((ser) => ser?.name)
  //             ?.join(", "),
  //           date: reservation?.date,
  //           debut: reservation?.start_time?.split(":").slice(0, 2).join(":"),
  //           fin: reservation?.end_time?.split(":").slice(0, 2).join(":"),
  //           totalPrice: totalPrice,
  //         })
  //       ).then((response) => response);

  //       toast.success("La réservation a été créée avec succès !", {
  //         autoClose: 400,
  //         onClose: () => {
  //           dispatch(FetchData(`/clientBookings/${client?.id}`, BOOKINGS));
  //           dispatch({ type: INIT_BOOKING });
  //         },
  //       });
  //       setLoading(false);
  //       setActive(false);
  //     } catch (err) {
  //       setLoading(false);
  //       setLoadingState("");
  //       toast.error(
  //         err.response?.data?.message || "Échec du paiement par portefeuille."
  //       );
  //       console.error("Wallet payment error:", err);
  //     }
  //     return; // Exit the function to prevent further processing
  //   }

  //   // If payment method is NAPS, trigger the NAPS payment process
  //   if (paymentMethod === "NAPS") {
  //     setLoading(true);
  //     setLoadingState("Préparation du paiement NAPS...");

  //     try {
  //       // Call the NAPS payment API
  //       const response = await apiClient().post("/payments/generate-link", {
  //         amount: finalTotalPrice, // Convert to string with 2 decimal places
  //         order_id: `skipp-${Date.now()}`, // Generate a unique order ID
  //         customer_email: client?.email || formData?.email,
  //         customer_name: client?.first_name || formData?.first_name,
  //         phone: "0606060606",
  //         address: "marrakech", // Replace with actual address if available
  //         city: "marrakech", // Replace with actual city if available
  //         state: "marrakech", // Replace with actual state if available
  //         country: "MA",
  //         postcode: "40000", // Replace with actual postcode if available
  //         booking_id: 139,
  //         salon_id: reservation?.salon?.id,
  //           date: reservation?.date,
  //           start_time: reservation?.start_time,
  //           end_time: reservation?.end_time,
  //           employee_id: reservation?.employee_id,
  //           user_id: guest
  //             ? null
  //             : (newclient?.data?.data?.id
  //                 ? newclient?.data?.data?.id
  //                 : newclient?.id) || client?.id,
  //           guest_id: guest ? guest?.id : null,
  //           availability_id: availability?.id,
  //           note: formData?.appointmentNotes,
  //           type: "reservation",
  //           name: guest?.id
  //             ? guest?.name
  //             : newclient?.data?.data?.id
  //             ? `${newclient?.data?.data?.first_name} ${newclient?.data?.data?.last_name}`
  //             : `${client?.first_name} ${client?.last_name}`,
  //           email:
  //             (newclient?.data?.data?.id
  //               ? newclient?.data?.data?.email
  //               : client?.email) || guest?.email,
  //           services: reservation?.services
  //             ?.map((ser) => ser?.name)
  //             ?.join(", "),
  //           date: reservation?.date,
  //           debut: reservation?.start_time?.split(":").slice(0, 2).join(":"),
  //           fin: reservation?.end_time?.split(":").slice(0, 2).join(":"),
  //           totalPrice: totalPrice,
  //       });

  //       if (response.data.status === "success") {
  //         // Redirect to the NAPS payment URL
  //         window.location.href = response.data.payment_url;
  //       } else {
  //         throw new Error(response.data.message);
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //       setLoadingState("");
  //       toast.error(
  //         err.response?.data?.message ||
  //           "Échec de l'initiation du paiement NAPS"
  //       );
  //       console.error("NAPS payment error:", err);
  //     }
  //     return; // Exit the function to prevent further processing
  //   }

  //   // If payment method is not NAPS, proceed with the regular payment flow
  //   setIsSubmitting(true);

  //   if (paymentMethod === "CASH") {
  //     setIsSubmitting(true);
  //     setLoadingState("Vérification d'utilisateur...");
  //     let formData = {
  //       ip_address: client?.ip_address ? client.ip_address : "",
  //       email: client?.email ? client.email : "",
  //     };

  //     try {
  //       const response = await apiClient().post(`/check-blacklist`, formData);

  //       if (response.data?.success) {
  //         localStorage.setItem("blacklist", true);
  //         // If the user is blacklisted, show an error and exit the function
  //         setIsSubmitting(false);
  //         setLoadingState("");
  //         toast.error(response.data.message);
  //         return; // Exit the handleSubmit function
  //       } else {
  //         localStorage.removeItem("blacklist");
  //       }
  //     } catch (error) {
  //       console.error("Error checking blacklist:", error);
  //       toast.error("An error occurred while checking the blacklist.");
  //     }
  //   }

  //   if (!venueMarketingConsent || !marketingConsent) {
  //     return toast.error("Vous devez accepter les conditions !");
  //   }

  //   setIsSubmitting(true);

  //   if (
  //     new Date(`${reservation.date}T${reservation.start_time}`) < new Date()
  //   ) {
  //     setLoadingState("");
  //     setIsSubmitting(false);
  //     return toast.error(
  //       "La session que vous souhaitez réserver a déjà débuté."
  //     );
  //   }

  //   try {
  //     setLoading(true);
  //     let guest = null;
  //     let availability = null;
  //     let booking = null;
  //     let payment = null;
  //     let newclient = null;
  //     let responseEmail = null;

  //     setLoadingState("Traitement de vos informations...");

  //     if (mode === 1) {
  //       // Guest mode
  //       try {
  //         guest = await dispatch(
  //           RegisterGuest({
  //             name: formData?.name,
  //             email: formData?.email,
  //             phone: formData?.phone,
  //           })
  //         ).then((response) => response);
  //       } catch (error) {
  //         setLoading(false);
  //         if (error.response?.data?.message?.includes("email")) {
  //           setLoadingState("");
  //           setIsSubmitting(false);
  //           return toast.error(
  //             "Cet email est déjà utilisé. Veuillez vous connecter ou utiliser un autre email."
  //           );
  //         }
  //         setLoadingState("");
  //         setIsSubmitting(false);
  //         return toast.error(
  //           "Erreur lors de l'enregistrement en tant qu'invité."
  //         );
  //       }
  //     } else if (mode === 2) {
  //       // Register mode
  //       if (formData?.password?.length < 6) {
  //         setLoading(false);
  //         setLoadingState("");
  //         setIsSubmitting(false);
  //         return toast.error(
  //           "Le mot de passe doit comporter au moins 6 caractères."
  //         );
  //       }

  //       if (formData?.password !== formData?.confirmePassword) {
  //         setLoading(false);
  //         setLoadingState("");
  //         setIsSubmitting(false);
  //         return toast.error("Les mots de passe ne correspondent pas !");
  //       }

  //       try {
  //         newclient = await dispatch(
  //           registerClient({
  //             first_name: formData?.first_name,
  //             last_name: formData?.last_name,
  //             email: formData?.email,
  //             phone: formData?.phone,
  //             password: formData?.password,
  //           })
  //         ).then((response) => response);
  //         if (!newclient.success) {
  //           setLoading(false);
  //           setLoadingState("");
  //           setIsSubmitting(false);
  //           return toast.error(newclient?.message);
  //         }
  //       } catch (error) {}
  //     } else if (mode === 3) {
  //       // Login mode
  //       try {
  //         newclient = await dispatch(
  //           loginClient({
  //             email: formData?.email,
  //             password: formData?.password,
  //           })
  //         ).then((response) => response);

  //         if (!newclient.success) {
  //           setLoading(false);
  //           setLoadingState("");
  //           setIsSubmitting(false);
  //           return toast.error("Email ou mot de passe incorrect.");
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         if (error.response?.status === 401) {
  //           setLoadingState("");
  //           setIsSubmitting(false);
  //           return toast.error("Email ou mot de passe incorrect.");
  //         }
  //         if (error.response?.status === 403) {
  //           setLoadingState("");
  //           setIsSubmitting(false);
  //           return toast.error(
  //             "Votre compte est désactivé. Veuillez contacter le support."
  //           );
  //         }
  //         setLoadingState("");
  //         setIsSubmitting(false);
  //         return toast.error("Erreur de connexion. Veuillez réessayer.");
  //       }
  //     } else {
  //       newclient = client;
  //     }

  //     // Validate client/guest exists before proceeding
  //     if (!guest && !newclient && !client) {
  //       setLoading(false);
  //       setLoadingState("");
  //       setIsSubmitting(false);
  //       return toast.error("Une erreur est survenue avec l'authentification.");
  //     }

  //     // Proceed with booking creation
  //     try {
  //       setLoadingState("Création de votre réservation...");

  //       availability = await dispatch(
  //         CreateAvailability({
  //           salon_id: reservation?.salon?.id,
  //           date: reservation?.date,
  //           start_time: reservation?.start_time,
  //           end_time: reservation?.end_time,
  //           employee_id: reservation?.employee_id,
  //         })
  //       ).then((response) => response);

  //       booking = await dispatch(
  //         CreateBooking({
  //           user_id: guest
  //             ? null
  //             : (newclient?.data?.data?.id
  //                 ? newclient?.data?.data?.id
  //                 : newclient?.id) || client?.id,
  //           guest_id: guest ? guest?.id : null,
  //           salon_id: reservation?.salon?.id,
  //           availability_id: availability?.id,
  //           note: formData?.appointmentNotes,
  //         })
  //       ).then((response) => response);

  //       await dispatch(
  //         AttacheServicesToBoking(booking?.id, reservation?.services)
  //       );

  //       setLoadingState("Traitement du paiement...");

  //       payment = await dispatch(
  //         CreatePayment({
  //           booking_id: booking?.id,
  //           amount: totalPrice,
  //           payment_method: paymentMethod,
  //           transaction_id: "TRANSACTION_ID",
  //         })
  //       ).then((response) => response);

  //       setLoadingState("Envoi de la confirmation...");

  //       responseEmail = await dispatch(
  //         SendEmail({
  //           type: "reservation",
  //           name: guest?.id
  //             ? guest?.name
  //             : newclient?.data?.data?.id
  //             ? `${newclient?.data?.data?.first_name} ${newclient?.data?.data?.last_name}`
  //             : `${client?.first_name} ${client?.last_name}`,
  //           email:
  //             (newclient?.data?.data?.id
  //               ? newclient?.data?.data?.email
  //               : client?.email) || guest?.email,
  //           services: reservation?.services
  //             ?.map((ser) => ser?.name)
  //             ?.join(", "),
  //           date: reservation?.date,
  //           debut: reservation?.start_time?.split(":").slice(0, 2).join(":"),
  //           fin: reservation?.end_time?.split(":").slice(0, 2).join(":"),
  //           totalPrice: totalPrice,
  //         })
  //       ).then((response) => response);

  //       setFormData(null);

  //       toast.success("La réservation a été créée avec succès !", {
  //         autoClose: 400,
  //         onClose: () => {
  //           if (!guest) {
  //             dispatch(
  //               FetchData(
  //                 `/clientBookings/${newclient?.id || client?.id}`,
  //                 BOOKINGS
  //               )
  //             );
  //           }
  //           dispatch({ type: INIT_BOOKING });
  //         },
  //       });
  //       setLoading(false);
  //       setActive(false);
  //     } catch (error) {
  //       setLoading(false);
  //       setLoadingState("");
  //       setIsSubmitting(false);
  //       console.error("Booking creation error:", error);
  //       toast.error(
  //         "Une erreur est survenue lors de la création de la réservation."
  //       );
  //       setActive(true);
  //     }
  //   } catch (err) {
  //     console.error("Global error:", err);
  //     setLoading(false);
  //     setLoadingState("");
  //     setIsSubmitting(false);
  //     toast.error("Une erreur inattendue est survenue. Veuillez réessayer.");
  //     setActive(true);
  //   } finally {
  //     setLoadingState("");
  //     setIsSubmitting(false);
  //   }
  // };

  const PaymentProcessor = {
    async handleNapsPayment(userData, paymentData) {
      const response = await apiClient().post("/payments/generate-link", {
        amount: paymentData.finalTotalPrice,
        order_id: `skipp-${Date.now()}`,
        customer_email: userData.email,
        customer_name: userData.isGuest ? userData.name : `${userData.first_name} ${userData.last_name}`,
        phone: userData.phone || "0606060606",
        address: "marrakech",
        city: "marrakech",
        state: "marrakech",
        country: "MA",
        postcode: "40000",
        ...paymentData.reservationDetails,
        user_type: userData.isGuest ? 'guest' : 'client',
        user_data: userData,
      });
  
      if (response.data.status !== "success") {
        throw new Error("Échec de l'initiation du paiement NAPS");
      }
      return response.data.payment_url;
    },
  
    async handleWalletPayment(userId, amount) {
      const balance = await this.fetchWalletBalance(userId);
      if (balance < amount) {
        throw new Error("Solde insuffisant dans le portefeuille");
      }
      await this.deductFromWallet(userId, amount);
    },
  
    async fetchWalletBalance(userId) {
      const response = await apiClient().get(`/user-balance/${userId}/balance`);
      return response.data.balance;
    },
  
    async deductFromWallet(userId, amount) {
      await apiClient().post(`/wallet/deduct`, { user_id: userId, amount });
    }
  };
  
  const BookingService = {
    async createBooking(dispatch, bookingData) {
      const availability = await dispatch(CreateAvailability(bookingData.availability));
      const booking = await dispatch(CreateBooking({
        user_id: bookingData.userId,
        guest_id: bookingData.guestId,
        salon_id: bookingData.salonId,
        availability_id: availability?.id,
        note: bookingData.note,
      }));
  
      await dispatch(AttacheServicesToBoking(booking?.id, bookingData.services));
      return booking;
    },
  
    async createPayment(dispatch, bookingId, amount, method) {
      return dispatch(CreatePayment({
        booking_id: bookingId,
        amount,
        payment_method: method,
        transaction_id: method === "WALLET" ? "WALLET_TRANSACTION" : "CASH_TRANSACTION",
      }));
    }
  };
  
    // State definitions remain the same
  
    // const handleSubmit = async (e) => {
    //   e.preventDefault();
  
    //   if (!venueMarketingConsent || !marketingConsent) {
    //     return toast.error("Vous devez accepter les conditions !");
    //   }
  
    //   setIsSubmitting(true);
    //   setLoading(true);
    //   setLoadingState("Vérification d'utilisateur...");
  
    //   try {
    //     const userData = await getUserData();
    //     const reservationDetails = {
    //       salon_id: reservation?.salon?.id,
    //       date: reservation?.date,
    //       start_time: reservation?.start_time,
    //       end_time: reservation?.end_time,
    //       employee_id: reservation?.employee_id,
    //       services: reservation?.services,
    //       note: formData?.appointmentNotes
    //     };
  
    //     if (paymentMethod === "NAPS") {
    //       const paymentUrl = await PaymentProcessor.handleNapsPayment(userData, {
    //         finalTotalPrice,
    //         reservationDetails
    //       });
    //       window.location.href = paymentUrl;
    //       return;
    //     }
  
    //     if (paymentMethod === "WALLET") {
    //       await PaymentProcessor.handleWalletPayment(userData.id, finalTotalPrice);
    //     }
  
    //     const booking = await BookingService.createBooking(dispatch, {
    //       userId: mode === 1 ? null : userData.id,
    //       guestId: mode === 1 ? userData.id : null,
    //       salonId: reservation?.salon?.id,
    //       availability: reservationDetails,
    //       services: reservation?.services,
    //       note: formData?.appointmentNotes
    //     });
  
    //     await BookingService.createPayment(dispatch, booking.id, finalTotalPrice, paymentMethod);
    //     await sendConfirmationEmail(userData);
  
    //     handleBookingSuccess(userData);
    //   } catch (error) {
    //     handleBookingError(error);
    //   } finally {
    //     setLoadingState("");
    //     setIsSubmitting(false);
    //     setLoading(false);
    //   }
    // };
  
    // // Helper functions
    // const getUserData = async () => {
    //   if (mode === 1) {
    //     return dispatch(RegisterGuest(formData));
    //   } 
    //   if (mode === 2 || mode === 3) {
    //     const action = mode === 2 ? registerClient : loginClient;
    //     const response = await dispatch(action(formData));
    //     if (!response.success) {
    //       throw new Error(mode === 2 ? "Échec de l'inscription" : "Email ou mot de passe incorrect");
    //     }
    //     return response.data?.data || response;
    //   }
    //   return client;
    // };
  
    // const sendConfirmationEmail = async (userData) => {
    //   await dispatch(SendEmail({
    //     type: "reservation",
    //     name: mode === 1 ? userData.name : `${userData.first_name} ${userData.last_name}`,
    //     email: userData.email,
    //     services: reservation?.services?.map(ser => ser?.name)?.join(", "),
    //     date: reservation?.date,
    //     debut: reservation?.start_time?.split(":").slice(0, 2).join(":"),
    //     fin: reservation?.end_time?.split(":").slice(0, 2).join(":"),
    //     totalPrice: finalTotalPrice,
    //   }));
    // };
  
    // const handleBookingSuccess = (userData) => {
    //   toast.success("La réservation a été créée avec succès !");
    //   if (mode !== 1) {
    //     dispatch(FetchData(`/clientBookings/${userData.id}`, BOOKINGS));
    //   }
    //   dispatch({ type: INIT_BOOKING });
    //   setActive(false);
    // };
  
    // const handleBookingError = (error) => {
    //   console.error("Error:", error);
    //   toast.error(error.message || "Une erreur inattendue est survenue");
    //   setActive(true);
    // };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      if (!venueMarketingConsent || !marketingConsent) {
        return toast.error("Vous devez accepter les conditions !");
      }
    
      setIsSubmitting(true);
      setLoading(true);
      setLoadingState("Vérification d'utilisateur...");
    
      try {
        // Handle NAPS payment
        if (paymentMethod === "NAPS") {
          await handleNapsPayment();
          return;
        }
    
        // Handle CASH and WALLET payments
        const user = await handleUserAuthentication();
        if (!user) {
          throw new Error("Erreur d'authentification de l'utilisateur.");
        }
    
        // Check wallet balance for WALLET payments
        if (paymentMethod === "WALLET") {
          await handleWalletPayment(user);
        }
        
    
        // Create booking and related entities
        const booking = await createBooking(user);
    
        // Send confirmation email
        await sendConfirmationEmail(user, booking);
    
        // Success handling
        toast.success("La réservation a été créée avec succès !");
        if (mode !== 1) {
          dispatch(FetchData(`/clientBookings/${user.id}`, BOOKINGS));
        }
        dispatch({ type: INIT_BOOKING });
        setActive(false);
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.message || "Une erreur inattendue est survenue");
        setActive(true);
      } finally {
        setLoadingState("");
        setIsSubmitting(false);
        setLoading(false);
      }
    };
    
    // Helper function for NAPS payment
    const handleNapsPayment = async () => {
      const userData = mode === 1 ? 
        { isGuest: true, ...formData } : 
        mode === 0 ? 
          client : 
          { isClient: true, ...formData };
    
      const paymentData = {
        amount: finalTotalPrice,
        order_id: `skipp-${Date.now()}`,
        customer_email: userData.email,
        customer_name: userData.isGuest ? 
          userData.name : 
          `${userData.first_name} ${userData.last_name}`,
        phone: userData.phone || "0606060606",
        address: "marrakech",
        city: "marrakech",
        state: "marrakech",
        country: "MA",
        postcode: "40000",
        salon_id: reservation?.salon?.id,
        date: reservation?.date,
        start_time: reservation?.start_time,
        end_time: reservation?.end_time,
        employee_id: reservation?.employee_id,
        user_type: userData.isGuest ? 'guest' : 'client',
        user_data: userData,
        services: reservation?.services,
        note: formData?.appointmentNotes
      };
    
      const response = await apiClient().post("/payments/generate-link", paymentData);
      if (response.data.status === "success") {
        window.location.href = response.data.payment_url;
        return;
      }
      throw new Error("Échec de l'initiation du paiement NAPS");
    };
    
    // Helper function for user authentication
    const handleUserAuthentication = async () => {
      if (mode === 1) {
        return await dispatch(RegisterGuest({
          name: formData?.name,
          email: formData?.email,
          phone: formData?.phone,
        }));
      } else if (mode === 2 || mode === 3) {
        const action = mode === 2 ? registerClient : loginClient;
        const response = await dispatch(action(formData));
        if (!response.success) {
          throw new Error(mode === 2 ? 
            "Échec de l'inscription" : 
            "Email ou mot de passe incorrect"
          );
        }
        return response.data?.data || response;
      } else {
        return client;
      }
    };
    
    // Helper function for wallet payment
    const handleWalletPayment = async (user) => {
      const balance = await fetchWalletBalance(user.id);
      if (balance < finalTotalPrice) {
        throw new Error("Solde insuffisant dans le portefeuille");
      }
      await deductFromWallet(user.id, finalTotalPrice);
    };
    
    // Helper function for creating a booking
    const createBooking = async (user) => {
      setLoadingState("Création de votre réservation...");
      const availability = await dispatch(CreateAvailability({
        salon_id: reservation?.salon?.id,
        date: reservation?.date,
        start_time: reservation?.start_time,
        end_time: reservation?.end_time,
        employee_id: reservation?.employee_id,
      }));
    
      const booking = await dispatch(CreateBooking({
        user_id: mode === 1 ? null : user.id,
        guest_id: mode === 1 ? user.id : null,
        salon_id: reservation?.salon?.id,
        availability_id: availability?.id,
        note: formData?.appointmentNotes,
      }));
    
      await dispatch(AttacheServicesToBoking(booking?.id, reservation?.services));
    
      await dispatch(CreatePayment({
        booking_id: booking?.id,
        amount: finalTotalPrice,
        payment_method: paymentMethod,
        transaction_id: paymentMethod === "WALLET" ? "WALLET_TRANSACTION" : "CASH_TRANSACTION",
      }));
    
      return booking;
    };
    
    // Helper function for sending confirmation email
    const sendConfirmationEmail = async (user, booking) => {
      await dispatch(SendEmail({
        type: "reservation",
        name: mode === 1 ? user.name : `${user.first_name} ${user.last_name}`,
        email: user.email,
        services: reservation?.services?.map(ser => ser?.name)?.join(", "),
        date: reservation?.date,
        debut: reservation?.start_time?.split(":").slice(0, 2).join(":"),
        fin: reservation?.end_time?.split(":").slice(0, 2).join(":"),
        totalPrice: finalTotalPrice,
      }));
    };

  return (
    <div className="checkout-page relative">
      <PaymentSuccessModal />
      {isSubmitting && <LoadingOverlay message={loadingState} />}
      <div className="data-client">
        <div className="title-pay">Paiement</div>
        <form onSubmit={handleSubmit}>
          {mode !== 0 && (
            <div className="modes">
              <button
                type="button"
                className={`${mode === 1 ? "active text-white" : "text-black"}`}
                onClick={() => handleSwitchMode(1)}
              >
                Invité
              </button>
              <button
                type="button"
                className={`${mode === 2 ? "active text-white" : "text-black"}`}
                onClick={() => handleSwitchMode(2)}
              >
                S'inscrire
              </button>
              <button
                type="button"
                className={`${mode === 3 ? "active text-white" : "text-black"}`}
                onClick={() => handleSwitchMode(3)}
              >
                Déjà inscré
              </button>
            </div>
          )}
          {mode === 1 && (
            <div className="data-guest">
              <div className="info-line">
                <span>Mode invité</span>
                <span>* Champ requis</span>
              </div>
              <div className="">
                <label htmlFor="name">Prénom et nom *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData?.name || ""}
                  onChange={handleChange}
                  placeholder="Votre prénom et nom"
                  required={true}
                />
              </div>
              <div>
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData?.email || ""}
                  onChange={handleChange}
                  placeholder="Votre email"
                  required={true}
                />
              </div>
              <div>
                <label htmlFor="telephone">Numéro de téléphone *</label>
                <input
                  type="tel"
                  id="telephone"
                  name="phone"
                  value={formData?.phone || ""}
                  onChange={handleChange}
                  placeholder="ex: 06 12 34 56 78"
                  required={true}
                />
              </div>
            </div>
          )}
          {mode === 2 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <span className="text-lg font-semibold text-[#000]"></span>
                <span className="font-semibold text-sm text-[#f79d68]">
                  * Champ requis
                </span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-3.5">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="first_name"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Prénom *
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData?.first_name || ""}
                    onChange={handleChange}
                    placeholder="Votre prénom"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="last_name"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Nom *
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={formData?.last_name || ""}
                    onChange={handleChange}
                    placeholder="Votre nom"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData?.email || ""}
                    onChange={handleChange}
                    placeholder="Votre email"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="phone"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Numéro de téléphone *
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData?.phone || ""}
                    onChange={handleChange}
                    placeholder="ex: 06 12 34 56 78"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="password"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Mot de passe *
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData?.password || ""}
                    onChange={handleChange}
                    placeholder="Votre mot de passe"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="confirmePassword"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Confirmer Mot de passe *
                  </label>
                  <input
                    type="password"
                    id="confirmePassword"
                    name="confirmePassword"
                    value={formData?.confirmePassword || ""}
                    onChange={handleChange}
                    placeholder="Confirmer votre mot de passe"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
              </div>
            </div>
          )}
          {mode === 3 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <span className="text-lg font-semibold text-[#000]"></span>
                <span className="font-semibold text-sm text-[#f79d68]">
                  * Champ requis
                </span>
              </div>
              <div className="grid grid-cols-1 gap-x-2 gap-y-3.5">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData?.email || ""}
                    onChange={handleChange}
                    placeholder="Votre email"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="password"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Mot de passe *
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData?.password || ""}
                    onChange={handleChange}
                    placeholder="Votre mot de passe"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="data-guest">
            <div className="">
              <label htmlFor="appointmentNotes">
                Notes liées au rendez-vous (Optionnel)
              </label>
              <textarea
                id="appointmentNotes"
                name="appointmentNotes"
                value={formData?.appointmentNotes || ""}
                onChange={handleChange}
                placeholder="Vos notes..."
              />
            </div>
          </div>
          <div className="payment-box">
            <div className="payment-info">
              <span className="payment-title">Choix du paiment</span>
              <span className="payment-description">
                Payez votre traitement à l'avance afin de ne pas avoir à gérer
                d'argent liquide sur place.
              </span>
            </div>
            <div className="payment-method">
              <div className="payment-method-info">
                <p className="payment-method-description">
                  Choisissez votre mode de paiement :
                </p>
              </div>
              <RadioGroup
                name="paymentMethod"
                value={paymentMethod}
                onChange={(event) => setPaymentMethod(event.target.value)}
                className="group-cust-checks"
              >
                {(localStorage.getItem("blacklist") === null ||
                  localStorage.getItem("blacklist") === false) && (
                  <FormControlLabel
                    className="cust-check-input w-full"
                    value="CASH"
                    control={<Radio sx={{ color: "var(--main-color-one)" }} />}
                    label={
                      <span
                        className="formselectpay"
                        style={{ fontFamily: "CustomFont" }}
                      >
                        <span>Payer en espèces à l'arrivée</span>
                        <PaymentsIcon
                          style={{
                            fontSize: "30px",
                            marginRight: "8px",
                            color: "var(--main-color-one)",
                          }}
                        />
                      </span>
                    }
                  />
                )}
                <FormControlLabel
                  className="cust-check-input w-full"
                  value="NAPS"
                  control={<Radio sx={{ color: "var(--main-color-one)" }} />}
                  label={
                    <span
                      className="formselectpay"
                      style={{ fontFamily: "CustomFont" }}
                    >
                      <span>Payer par carte</span>
                    </span>
                  }
                />

                <FormControlLabel
                  className="cust-check-input w-full"
                  value="WALLET"
                  control={<Radio sx={{ color: "var(--main-color-one)" }} />}
                  label={
                    <span
                      className="formselectpay"
                      style={{ fontFamily: "CustomFont" }}
                    >
                      <span>Payer avec le portefeuille</span>
                    </span>
                  }
                />
              </RadioGroup>
            </div>
          </div>
          {paymentMethod === "NAPS" && (
            <div className="w-full flex flex-col gap-2 my-2 border p-4 rounded-md shadow-sm">
              <label
                htmlFor="giftCode"
                className="block text-bold font-medium text-gray-700"
              >
                Code cadeau (optionnel)
              </label>
              <div className="flex items-center gap-2">
                <div className="flex-1">
                  <input
                    type="text"
                    id="giftCode"
                    name="giftCode"
                    value={giftCode}
                    onChange={handleGiftCodeChange}
                    placeholder="Entrez votre code cadeau"
                    className={`block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2 ${
                      isGiftCodeSubmitted ? "bg-gray-100" : ""
                    }`}
                    disabled={isGiftCodeSubmitted}
                  />
                </div>
                {isGiftCodeSubmitted ? (
                  <button
                    type="button"
                    onClick={removeGiftCode}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <XCircle className="h-4 w-4 mr-1" />
                    Retirer
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={applyGiftCode}
                    disabled={isApplyingCode}
                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
                      isApplyingCode
                        ? "bg-indigo-400 cursor-not-allowed"
                        : "bg-indigo-600 hover:bg-indigo-700"
                    } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                  >
                    {isApplyingCode ? (
                      <div className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                        Validation...
                      </div>
                    ) : (
                      "Appliquer"
                    )}
                  </button>
                )}
              </div>
              {isGiftCodeValid && (
                <div className="mt-2 text-sm text-green-600 flex items-center justify-between">
                  <span>Réduction de {discount}% appliquée avec succès !</span>
                  
                </div>
              )}
            </div>
          )}

          <div className="checks-box" data-cy="MarketingConsent">
            <div className="stack">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingConsent}
                    onChange={(event) =>
                      setMarketingConsent(event.target.checked)
                    }
                  />
                }
                label={
                  <Typography className="text-regle text-justify">
                    Cochez cette case si vous souhaitez recevoir des e-mails de
                    Skipp contenant les dernières actualités de Skipp et des
                    offres pour des produits et services similaires. Vous pouvez
                    vous désinscrire à tout moment en cliquant sur le lien de
                    désabonnement dans les e-mails.
                  </Typography>
                }
              />
            </div>
            <div className="stack">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={venueMarketingConsent}
                    onChange={(event) =>
                      setVenueMarketingConsent(event.target.checked)
                    }
                  />
                }
                label={
                  <Typography className="text-regle text-justify">
                    Merci de cocher cette case pour autoriser le salon auprès
                    duquel vous avez réservé à vous envoyer des e-mails et SMS
                    concernant ses services.
                  </Typography>
                }
              />
            </div>
            <Typography variant="caption" className="caption text-justify">
              Vous pouvez modifier vos préférences en cliquant sur 'se
              désabonner' ou en contactant directement votre salon à propos des
              emails qui sont envoyés et sur l'utilisation de vos données
              personnelles. Pour en savoir plus, consultez notre{" "}
              <span
                className="text-blue-400 underline hover:no-underline cursor-pointer"
                onClick={() => window.open("/politique", "_blank")}
              >
                politique de confidentialité
              </span>
              .
            </Typography>
          </div>
          <button
            type="submit"
            className={`w-full flex items-center justify-center p-2 rounded-lg text-white 
              ${
                isSubmitting
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-orange-400 hover:bg-orange/90"
              }`}
            disabled={isSubmitting}
          >
            {!active ? (
              "Payé"
            ) : isSubmitting ? (
              <div className="flex items-center gap-2">
                <LoadingSpinner />
                <span>Traitement en cours...</span>
              </div>
            ) : (
              "Valider"
            )}
          </button>
        </form>
      </div>
      <div className="pannier-data">
        <div className="info-res-salon">
          <span>{reservation?.salon?.name}</span>
          <div className="about-app">
            <span className="time">
              {reservation?.start_time?.split(":").slice(0, 2).join(":")} -{" "}
              {reservation?.end_time?.split(":").slice(0, 2).join(":")}
            </span>

            <div className="date-long">
              <span className="capitalize">
                {reservation?.date && FormatDateToFrench(reservation?.date)}
              </span>
              <span>
                {duration?.hours && duration?.hours !== 0
                  ? `${duration?.hours} h `
                  : ""}{" "}
                {duration?.minutes && duration?.minutes !== 0
                  ? `${duration?.minutes} min`
                  : ""}
              </span>
            </div>
          </div>
          <div className="flex justify-center gap-2 text-center text-xs md:text-sm py-2 font-semibold">
            {reservation?.services?.map((ser) => ser?.name)?.join(", ")}
          </div>
          <div className="montant">
            <span className="text-black">Montant à payer</span>
            {isGiftCodeValid ? <span>
              <span className="line-through text-red-600 text-[14px]">
                ({totalPrice} MAD)
              </span>
              {"  "}
              <span className="text-green-600">{finalTotalPrice} MAD</span>
            </span> : <span>
              <span className="text-black">
                {totalPrice} MAD
              </span>
            </span>}
          </div>
          <div className="mx-auto text-green-600">
            {discount > 0 && (
              <span className="discount-text">
                (Réduction de {discount}% appliquée)
              </span>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default CheckoutPage;
