import React, { useState, useEffect, useRef } from "react";
import {
  User,
  Bell,
  Settings,
  LogOut,
  Edit2,
  MapPin,
  Mail,
  Phone,
  Eye,
  EyeOff,
  EyeIcon,
  Wallet,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import { BASE_URL_IMAGE } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";
import UploadImage from "../../helpers/uploadImages";
import { logoutClient, UpdateClient } from "../../actions/auth";
import { ResetPassword } from "../../actions/data";
import { ArrowBack } from "@mui/icons-material";
import { HeaderMobile } from "../../components";

const MobileProfile = () => {
  const [activeSection, setActiveSection] = useState("profile");
  const client = useSelector((state) => state?.auth?.client);
  const dispatch = useDispatch();
  const balance = useSelector((state) => state?.auth?.balance);
  const [walletBalance, setWalletBalance] = useState(balance);
  // Profile View State
  const [notifications] = useState([
    {
      id: 1,
      title: "Nouvelle réservation",
      message: "Vous avez une nouvelle réservation",
      time: "5 min",
    },
    {
      id: 2,
      title: "Mise à jour du profil",
      message: "Votre profil a été mis à jour avec succès",
      time: "2h",
    },
  ]);

  // Edit Profile State
  const [formData, setFormData] = useState(client);
  const [selectedImage, setSelectedImage] = useState(
    client?.avatar ? BASE_URL_IMAGE + client?.avatar : ProfileImg
  );
  const fileInputRef = useRef(null);

  // Password Change State
  const [passwordState, setPasswordState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    passwordCriteria: {
      length: false,
      specialChar: false,
      numeric: false,
    },
  });

  useEffect(() => {
    setFormData(client);
    setSelectedImage(
      client?.avatar ? BASE_URL_IMAGE + client?.avatar : ProfileImg
    );
  }, [client]);

  // Edit Profile Handlers
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormData({ ...formData, avatar: file });
    }
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    const updateData = async () => {
      try {
        let newPath = false;
        if (formData?.avatar && formData?.avatar !== client?.avatar) {
          newPath = await UploadImage(
            "avatar",
            { avatar: formData?.avatar },
            { avatar: client?.avatar },
            "avatars"
          );
        }
        const data = {
          ...formData,
          avatar: !newPath ? formData?.avatar : newPath,
        };
        dispatch(UpdateClient(data, client?.id)).then((res) => {
          setSelectedImage(BASE_URL_IMAGE + res?.avatar);
          toast.success("Profil mis à jour avec succès");
          setActiveSection("profile");
        });
      } catch (err) {
        toast.error("Échec de la modification des informations d'utilisateur.");
        console.error(err);
      }
    };
    updateData();
  };

  // Password Change Handlers
  const validatePassword = (password) => {
    return {
      length: password?.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>_]/.test(password),
      numeric: /[0-9]/.test(password),
    };
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const updatedState = {
      ...passwordState,
      [name]: value,
    };

    if (name === "newPassword") {
      updatedState.passwordCriteria = validatePassword(value);
    }

    setPasswordState(updatedState);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword, passwordCriteria } =
      passwordState;

    if (
      !passwordCriteria.length ||
      !passwordCriteria.specialChar ||
      !passwordCriteria.numeric
    ) {
      toast.error(
        "Le nouveau mot de passe ne respecte pas les critères requis !"
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Les mots de passe ne sont pas compatibles !");
      return;
    }

    dispatch(
      ResetPassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
        id: client?.id,
      })
    )
      .then((result) => {
        if (result) {
          toast.success("Mot de passe mis à jour avec succès");
          setPasswordState({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            passwordCriteria: {
              length: false,
              specialChar: false,
              numeric: false,
            },
          });
          setActiveSection("profile");
        }
      })
      .catch((error) => {
        toast.error("Échec de la modification du mot de passe");
        console.error("Error:", error);
      });
  };

  const handleLogout = () => {
    dispatch(logoutClient());
  };

  // Render Methods
  const renderProfileView = () => (
    <>
      {/* Profile Header */}
      <HeaderMobile />
      <div className="h-20"></div>
      <div className="bg-gradient-to-r from-[#fd8000] to-[#ff944d] text-white px-6 py-3 shadow-lg">
        <div className="flex items-center space-x-4">
          <div className="w-20 h-20 rounded-full overflow-hidden border-4 border-white">
            <img
              src={selectedImage}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h2 className="text-xl mt-2 font-bold">
              {client?.first_name} {client?.last_name}
            </h2>
            <p className="text-sm opacity-80">
              Membre depuis {new Date(client?.created_at).getFullYear()}
            </p>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      {/* Action Buttons */}
      <div className="p-6 space-y-4">
        {/* Wallet Card */}
        <div className="bg-gradient-to-r from-orange-50 to-orange-400 p-4 rounded-xl text-black/80 mb-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm opacity-80">Mon Portefeuille</span>
            <div className="w-8 h-8 bg-white/40 rounded-full flex items-center justify-center">
              <Wallet className="w-5 h-5 text-black/80" />
            </div>
          </div>
          <div className="text-2xl font-bold mb-1">
            {walletBalance?.toFixed(2)} MAD
          </div>
          <div className="text-xs opacity-80">Solde disponible</div>
        </div>

        {/* Action Buttons Grid */}
        <div className="grid grid-cols-3 gap-3">
          <button
            className="flex flex-col items-center justify-center bg-orange-50 p-4 rounded-xl"
            onClick={() => setActiveSection("edit-profile")}
          >
            <Edit2 className="text-[#fd8000] mb-2" />
            <span className="text-xs text-black/80">Modifier</span>
          </button>
          <button
            className="flex flex-col items-center justify-center bg-orange-50 p-4 rounded-xl"
            onClick={() => setActiveSection("password")}
          >
            <EyeIcon className="text-[#fd8000] mb-2" />
            <span className="text-xs text-black/80">Mot de passe</span>
          </button>
          <button
            className="flex flex-col items-center justify-center bg-orange-50 p-4 rounded-xl"
            onClick={handleLogout}
          >
            <LogOut className="text-[#fd8000] mb-2" />
            <span className="text-xs text-black/80">Déconnexion</span>
          </button>
        </div>
      </div>

      {/* Contact Information */}
      <div className="p-6 space-y-4">
        <h3 className="text-lg text-orange-950 font-bold mb-4">
          Informations de contact
        </h3>
        {/* {client?.adresse && (
          <div className="flex items-center space-x-3 bg-orange-50 p-4 rounded-xl">
            <MapPin className="text-[#fd8000]" />
            <span className="text-sm">{client.adresse}</span>
          </div>
        )} */}
        {client?.email && (
          <div className="flex items-center space-x-3 bg-orange-50 p-4 rounded-xl">
            <Mail className="text-[#fd8000]" />
            <a href={`mailto:${client.email}`} className="text-black/80 text-sm">
              {client.email}
            </a>
          </div>
        )}
        {client?.phone && (
          <div className="flex items-center space-x-3 bg-orange-50 p-4 rounded-xl">
            <Phone className="text-[#fd8000]" />
            <a href={`tel:${client.phone}`} className="text-sm">
              {client.phone}
            </a>
          </div>
        )}
      </div>

      {/* Notifications Section */}
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg text-orange-950 font-bold">Notifications</h3>
          <Bell className="text-[#fd8000]" />
        </div>
        <div className="space-y-4">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="bg-orange-50 py-3 px-4 rounded-xl flex justify-between items-center"
            >
              <div>
                <h4 className="font-bold text-sm text-orange-800">
                  {notification.title}
                </h4>
                <p className="text-xs p-0 m-0 mt-2 text-orange-950">
                  {notification.message}
                </p>
              </div>
              <span className="text-xs">{notification.time}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const renderEditProfile = () => (
    <>
      <form onSubmit={handleProfileSubmit} className="">
        <header className="fixed top-[-2px] left-0 right-0 z-50 bg-white shadow-sm flex items-center justify-between px-4 h-20">
          <div className="flex-1 flex items-center justify-start space-x-2">
            <ArrowBack
              className="h-5 w-5 text-black" // Adjust size for better visibility
              onClick={() => setActiveSection("profile")}
            />
          </div>
          <div className="flex-1 flex items-center text-black font-bold text-base justify-center">
            Mon Profil
          </div>
          <div className="flex-1 flex items-center justify-end">
            <button type="submit" className="py-2 text-[#fd8000]">
              Enregistrer
            </button>
          </div>
        </header>
        <div className="h-20"></div>
        <div className="p-6">
          <div className="flex justify-center mb-6">
            <div className="relative w-24 h-24">
              <input
                type="file"
                id="avatar"
                onChange={handleChangeAvatar}
                className="hidden"
                ref={fileInputRef}
              />
              <img
                src={selectedImage}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover"
              />
              <button
                type="button"
                className="absolute bottom-0 right-0 bg-[#fd8000] text-white rounded-full w-8 h-8 flex items-center justify-center"
                onClick={() => fileInputRef.current.click()}
              >
                <Edit2 size={16} />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Prénom</label>
              <input
                type="text"
                name="first_name"
                value={formData?.first_name || ""}
                onChange={handleProfileChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Nom</label>
              <input
                type="text"
                name="last_name"
                value={formData?.last_name || ""}
                onChange={handleProfileChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData?.email || ""}
                onChange={handleProfileChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block mb-2">Téléphone</label>
              <input
                type="tel"
                name="phone"
                value={formData?.phone || ""}
                onChange={handleProfileChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2">Date de Naissance</label>
              <input
                type="date"
                name="birthday"
                value={formData?.birthday || ""}
                onChange={handleProfileChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2">Genre</label>
              <select
                name="gender"
                value={formData?.gender || ""}
                onChange={handleProfileChange}
                className="w-full p-2 border rounded"
              >
                <option className="text-xs" value="">
                  Sélectionner
                </option>
                <option className="text-xs" value="homme">
                  Homme
                </option>
                <option className="text-xs" value="femme">
                  Femme
                </option>
              </select>
            </div>
            {/* <div className="md:col-span-2">
            <label className="block mb-2">Adresse</label>
            <input
              type="text"
              name="adresse"
              value={formData?.adresse || ''}
              onChange={handleProfileChange}
              className="w-full p-2 border rounded"
            />
          </div> */}
          </div>
        </div>
      </form>
    </>
  );

  const renderPasswordChange = () => (
    <>
      <form onSubmit={handlePasswordSubmit} className="space-y-4 mt-20">
        <header className="fixed top-[-2px] left-0 right-0 z-50 bg-white shadow-sm flex items-center justify-between px-4 h-20">
          <div className="flex-1 flex items-center justify-start space-x-2">
            <ArrowBack
              className="h-5 w-5 text-black" // Adjust size for better visibility
              onClick={() => setActiveSection("profile")}
            />
          </div>
          <div className="flex-1 flex items-center text-black font-bold text-base justify-center">
            Mon Profil
          </div>
          <div className="flex-1 flex items-center justify-end">
            <button type="submit" className="py-2 text-[#fd8000]">
              Enregistrer
            </button>
          </div>
        </header>
        <div className="space-y-4 p-6">
          <div>
            <label className="block mb-2">Mot de passe actuel</label>
            <div className="relative">
              <input
                type={passwordState.showCurrentPassword ? "text" : "password"}
                name="currentPassword"
                value={passwordState.currentPassword}
                onChange={handlePasswordChange}
                className="w-full p-2 border rounded pr-10"
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() =>
                  setPasswordState((prev) => ({
                    ...prev,
                    showCurrentPassword: !prev.showCurrentPassword,
                  }))
                }
              >
                {passwordState.showCurrentPassword ? (
                  <EyeOff className="text-gray-500" size={20} />
                ) : (
                  <Eye className="text-gray-500" size={20} />
                )}
              </button>
            </div>
          </div>

          <div>
            <label className="block mb-2">Nouveau mot de passe</label>
            <div className="relative">
              <input
                type={passwordState.showNewPassword ? "text" : "password"}
                name="newPassword"
                value={passwordState.newPassword}
                onChange={handlePasswordChange}
                className="w-full p-2 border rounded pr-10"
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() =>
                  setPasswordState((prev) => ({
                    ...prev,
                    showNewPassword: !prev.showNewPassword,
                  }))
                }
              >
                {passwordState.showNewPassword ? (
                  <EyeOff className="text-gray-500" size={20} />
                ) : (
                  <Eye className="text-gray-500" size={20} />
                )}
              </button>
            </div>

            {/* Password Criteria */}
            <div className="mt-2 text-xs">
              <p
                className={`${
                  passwordState.passwordCriteria.length
                    ? "text-green-600"
                    : "text-red-500"
                }`}
              >
                • Au moins 8 caractères
              </p>
              <p
                className={`${
                  passwordState.passwordCriteria.specialChar
                    ? "text-green-600"
                    : "text-red-500"
                }`}
              >
                • Contient un caractère spécial
              </p>
              <p
                className={`${
                  passwordState.passwordCriteria.numeric
                    ? "text-green-600"
                    : "text-red-500"
                }`}
              >
                • Contient un chiffre
              </p>
            </div>
          </div>

          <div>
            <label className="block mb-2">
              Confirmer le nouveau mot de passe
            </label>
            <div className="relative">
              <input
                type={passwordState.showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={passwordState.confirmPassword}
                onChange={handlePasswordChange}
                className="w-full p-2 border rounded pr-10"
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() =>
                  setPasswordState((prev) => ({
                    ...prev,
                    showConfirmPassword: !prev.showConfirmPassword,
                  }))
                }
              >
                {passwordState.showConfirmPassword ? (
                  <EyeOff className="text-gray-500" size={20} />
                ) : (
                  <Eye className="text-gray-500" size={20} />
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );

  // Main render method
  return (
    <div className="bg-white min-h-fit">
      <Toaster position="top-right" />
      {activeSection === "profile" && renderProfileView()}
      {activeSection === "edit-profile" && renderEditProfile()}
      {activeSection === "password" && renderPasswordChange()}
    </div>
  );
};

export default MobileProfile;
