import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Mail,
  Lock,
  User,
  Phone,
  Eye,
  EyeOff,
  ArrowLeft,
  CheckCircle,
  AlertCircle,
  Loader2,
} from "lucide-react";
import { registerClient } from "../../actions/auth";
import { Skippwhite } from "../../assets/images";

function MobileRegister() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmePassword: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password matching validation
    if (formData.password !== formData.confirmePassword) {
      toast.error("Les mots de passe ne correspondent pas !", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }
    setIsLoading(true);

    if(localStorage.getItem('type_push')){
      if(localStorage.getItem('typepush')=='ios'){
        formData.push_ios=localStorage.getItem('token_push');
      }
      else{
        formData.push_android=localStorage.getItem('token_push');
      }
    }

    try {
      const result = await dispatch(registerClient(formData));

      if (result.success) {
        toast.success("Inscription terminée avec succès !", {
          icon: <CheckCircle className="text-green-500" />,
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      } else {
        toast.error("Échec de l'inscription : " + result.message, {
          icon: <AlertCircle className="text-red-500" />,
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center px-4">
      <div className="w-full flex items-center justify-center mb-8">
        <h2 className="text-2xl text-center font-bold text-white">
          Inscription
        </h2>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="relative">
            <input
              type="text"
              name="first_name"
              placeholder="Prénom"
              className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            <User
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
              size={22}
            />
          </div>

          <div className="relative">
            <input
              type="text"
              name="last_name"
              placeholder="Nom"
              className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
            <User
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
              size={22}
            />
          </div>
        </div>

        <div className="relative">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <Mail
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
            size={22}
          />
        </div>

        <div className="relative">
          <input
            type="tel"
            name="phone"
            placeholder="Téléphone"
            className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <Phone
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
            size={22}
          />
        </div>

        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Mot de passe"
            className="w-full py-4 pl-12 pr-12 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <Lock
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
            size={22}
          />
          <button
            type="button"
            className="absolute right-4 top-1/2 transform -translate-y-1/2"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeOff className="text-white" size={24} />
            ) : (
              <Eye className="text-white" size={24} />
            )}
          </button>
        </div>

        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            name="confirmePassword"
            placeholder="Confirmer mot de passe"
            className="w-full py-4 pl-12 pr-12 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
            value={formData.confirmePassword}
            onChange={handleChange}
            required
          />
          <Lock
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
            size={22}
          />
          <button
            type="button"
            className="absolute right-4 top-1/2 transform -translate-y-1/2"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeOff className="text-white" size={24} />
            ) : (
              <Eye className="text-white" size={24} />
            )}
          </button>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className={`w-full bg-white text-[#fd8000] py-4 rounded-xl uppercase font-bold 
            ${
              isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-white/90"
            } 
            transition duration-300 flex items-center justify-center`}
        >
          {isLoading ? (
            <Loader2 className="animate-spin" size={24} />
          ) : (
            "S'inscrire"
          )}
        </button>
      </form>

      <div className="mt-6 text-center">
        <div className="flex items-center justify-center">
          <div className="w-1/4 h-px bg-white/30"></div>
          <span className="mx-4 text-white/70">Ou</span>
          <div className="w-1/4 h-px bg-white/30"></div>
        </div>

        <div className="mt-4 text-white">
          {/* <p className="text-white mb-2">
            Vous avez déjà un compte ?
          </p> */}
          Vous avez déjà un compte ?
          <button
            onClick={() => navigate("/connexion")}
            className="ml-2  font-semibold"
          >
            Connexion
          </button>
        </div>
      </div>

      <Toaster
        position="top-center"
        toastOptions={{
          duration: 4000,
          success: {
            style: {
              background: "white",
              color: "black",
            },
          },
          error: {
            style: {
              background: "white",
              color: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default MobileRegister;
