import React, { useEffect, useState } from "react";
import "./style.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DatePickerCust } from "../../components";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Clock, User, X, ShoppingBag, Plus, ChevronRight, Calendar } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ADD_AVAILABILITY, ADD_SERVICES } from "../../constants/actions";
import { toast, Toaster } from "react-hot-toast";
import AddMinutesToTime from "../../helpers/AddMinutesToTime";
import { GetEmplAvailabilities } from "../../actions/data";
import FormatDuration from "../../helpers/FormatDuration";

function AfterPannier() {
  const reduxData = useSelector((state) => state);
  const [selectedEmpl, setSelectedEmpl] = useState("");
  const [emplAvailabilities, setEmplAvailabilities] = useState([]);
  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPrice, setTotalPrice] = useState(0);
  const [dateTimeRange, setDateTimeRange] = useState(null);

  useEffect(() => {
    if (
      !reduxData?.reservation?.salon ||
      !reduxData?.reservation?.services?.length
    ) {
      navigate("/detail-page", { state: { id: id } });
    }
  }, []);

  useEffect(() => {
    if (reduxData?.reservation?.employee_id) {
      const defaultEmp = reduxData?.reservation?.salon?.employees?.find(
        (emp) => emp?.id === reduxData?.reservation?.employee_id
      );
      setSelectedEmpl(defaultEmp?.id || "");
    } else {
      const defaultEmp = reduxData?.reservation?.salon?.employees[0];
      setSelectedEmpl(defaultEmp?.id || "");
    }
  }, [reduxData]);

  const handleDeleteService = (service) => {
    const serviceExists = reduxData?.reservation?.services?.includes(service);

    let updatedServices;
    if (serviceExists) {
      updatedServices = reduxData?.reservation?.services?.filter(
        (ser) => ser?.id !== service?.id
      );
    } else {
      updatedServices = [...(reduxData?.reservation?.services || []), service];
    }
    dispatch({ type: ADD_SERVICES, payload: updatedServices });
  };

  const renderSelectedValue = (value) => {
    if (value === null) {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <PersonOutlineIcon />
          <span>{"Un(e) employé(e)"}</span>
        </Box>
      );
    } else {
      const employe = reduxData?.reservation?.salon?.employees?.find(
        (emp) => emp?.id === parseInt(value)
      );
      return (
        <Box display="flex" alignItems="center">
          <span>
            {employe?.first_name} {employe?.last_name}
          </span>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (reduxData?.reservation?.services?.length) {
      const total = reduxData?.reservation?.services?.reduce(
        (sum, service) => sum + parseFloat(service?.pivot?.price),
        0
      );
      setTotalPrice(total.toFixed(2));
    }
  }, [reduxData?.reservation]);

  useEffect(() => {
    if (selectedEmpl) {
      const getFiltredAvailabilities = async (id) => {
        const result = await dispatch(GetEmplAvailabilities(id)).then(
          (response) => response
        );
        setEmplAvailabilities(result);
      };
      getFiltredAvailabilities(selectedEmpl);
    }
  }, [selectedEmpl]);

  const handleVerify = () => {
    if (dateTimeRange) {
      const totalMinutes = reduxData?.reservation?.services?.reduce(
        (sum, service) => {
          const durationStr = service?.pivot?.duration || "0000";
          const hours = parseInt(durationStr?.slice(0, 2), 10) || 0;
          const minutes = parseInt(durationStr?.slice(2, 4), 10) || 0;
          return sum + hours * 60 + minutes;
        },
        0
      );

      const availability = {
        date: dateTimeRange?.date,
        start_time: dateTimeRange?.start_time,
        end_time: AddMinutesToTime(dateTimeRange?.start_time, totalMinutes),
        employee_id: selectedEmpl,
      };
      
      dispatch({
        type: ADD_AVAILABILITY,
        payload: availability,
      });

      navigate("/checkout");
    } else {
      toast.error(
        "Il faut sélectionner l'heure de début de votre rendez-vous"
      );
    }
  };

  return (
    <div className="max-w-5xl mx-auto px-3 py-4">
      <div className="bg-white rounded-lg shadow-md">
        {reduxData?.reservation?.services?.length ? (
          <div className="grid grid-cols-1 lg:grid-cols-2">
            {/* Left Column - Employee Selection & Date Picker */}
            <div className="p-4 border-b lg:border-b-0 lg:border-r border-gray-100">
              {/* Employee Selection */}
              <div className="mb-4">
                <div className="flex items-center space-x-2 mb-3">
                  <div className="w-8 h-8 rounded-full bg-orange-100 flex items-center justify-center">
                    <User className="w-4 h-4 text-orange-500" />
                  </div>
                  <h3 className="text-sm text-xl font-bold text-gray-700">Professionnel</h3>
                </div>
                <select 
                  value={selectedEmpl || ""}
                  onChange={(e) => setSelectedEmpl(e.target.value)}
                  className="w-full text-base p-2 rounded-md border border-gray-200 focus:ring-1 focus:ring-orange-400 focus:border-orange-400"
                >
                  <option value="">Sélectionner un(e) employé(e)</option>
                  {reduxData?.reservation?.salon?.employees?.map((emp) => (
                    <option key={emp.id} value={emp.id}>
                      {emp.first_name} {emp.last_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Date Time Selection */}
              <div>
                <div className="flex items-center space-x-2 mb-3">
                  <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                    <Calendar className="w-4 h-4 text-blue-500" />
                  </div>
                  <h3 className="text-sm text-xl font-bold text-gray-700">Date et heure</h3>
                </div>
                <DatePickerCust
                  setDateTimeRange={setDateTimeRange}
                  reservation={reduxData?.reservation}
                  emplAvailabilities={emplAvailabilities}
                  selectedEmpl={selectedEmpl}
                  salon={reduxData?.reservation?.salon}
                />
              </div>
            </div>

            {/* Right Column - Cart */}
            <div className="p-4 bg-gray-50">
              <div className="flex items-center space-x-2 mb-3">
                <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
                  <ShoppingBag className="w-4 h-4 text-green-500" />
                </div>
                <h3 className="text-sm text-xl font-bold text-gray-700">Panier</h3>
              </div>

              <div className="space-y-2 max-h-64 overflow-y-auto mb-4 pr-1">
                {reduxData?.reservation?.services?.map((service) => (
                  <div 
                    key={service.id} 
                    className="bg-white rounded-md p-3 border border-gray-100 hover:border-orange-200 transition-colors duration-200"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-grow">
                        <h4 className="text-sm text-xl font-bold text-gray-800 mb-1">
                          {service.name}
                        </h4>
                        <div className="flex items-center text-xs text-gray-500">
                          <Clock className="w-3 h-3 mr-1" />
                          {FormatDuration(service?.pivot?.duration)}
                          <span className="mx-2">•</span>
                          <span className="text-xl font-bold text-green-600">
                            {service?.pivot?.price} Dhs
                          </span>
                        </div>
                      </div>
                      <button 
                        onClick={() => handleDeleteService(service)}
                        className="p-1 hover:bg-red-50 rounded-full text-gray-400 hover:text-red-500 transition-colors duration-200"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <button 
                onClick={() => navigate("/detail-page", { state: { id } })}
                className="w-full mb-4 p-2 font-lg flex items-center justify-center text-orange-500 hover:bg-orange-50 rounded-md border border-orange-200 transition-colors duration-200"
              >
                <Plus className="w-5 h-5 mr-1" />
                Ajouter d'autres services
              </button>

              <div className="bg-white rounded-md p-3 shadow-sm">
                <div className="flex justify-between items-center mb-3 text-sm">
                  <span className="text-xl font-bold text-gray-700">Total</span>
                  <span className="font-bold text-orange-500">{totalPrice} Dhs</span>
                </div>
                <button 
                  onClick={handleVerify}
                  className="w-full py-2 bg-gradient-to-r from-orange-400 to-orange-500 text-white text-sm rounded-md text-xl font-bold flex items-center justify-center hover:from-orange-500 hover:to-orange-600 transition-all duration-200"
                >
                  Confirmer
                  <ChevronRight className="w-4 h-4 ml-1" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-8 px-4">
            <div className="text-center space-y-3">
              <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center mx-auto">
                <ShoppingBag className="w-6 h-6 text-gray-400" />
              </div>
              <h3 className="text-sm text-xl font-bold text-gray-600">
                Votre panier est vide
              </h3>
              <button
                onClick={() => navigate("/detail-page", { state: { id } })}
                className="inline-flex items-center px-4 py-2 text-sm bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors duration-200"
              >
                <Plus className="w-4 h-4 mr-1" />
                Ajouter une prestation
              </button>
            </div>
          </div>
        )}
      </div>
      <Toaster />
    </div>
  );
};

export default AfterPannier;