import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Mail,
  Lock,
  Eye,
  EyeOff,
  ArrowLeft,
  CheckCircle,
  AlertCircle,
  Loader2,
} from "lucide-react";
import { loginClient } from "../../actions/auth";
import { apiClient } from "../../actions/api";
import { LOGIN_CLIENT } from "../../constants/actions";
import { gapi } from "gapi-script";

function MobileLogin() {
  const [formData, setFormData] = useState({});
  const [resetStep, setResetStep] = useState("login");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [codeVerification, setCodeVerification] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSPassword, setShowSPassword] = useState(false);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState({
    login: false,
    forgotPassword: false,
    verifyCode: false,
    resetPassword: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initializeGapi = () => {
    gapi.client.init({
      clientId: "30358834933-hrdugbcilc147l0raagusi309365v2td.apps.googleusercontent.com",
      scope: "",
    });
  };
  
  useEffect(() =>{
    // load and init google api scripts
    gapi.load("client:auth2", initializeGapi);
  })

  useEffect(() => {
    // Remove any existing Google script to avoid duplicates
    const existingScript = document.getElementById('google-script');
    if (existingScript) {
      existingScript.remove();
    }
  
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.id = 'google-script';
    script.async = true;
    script.defer = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);
  
    return () => {
      // Cleanup
      const script = document.getElementById('google-script');
      if (script) {
        script.remove();
      }
    };
  }, []);

  const initializeGoogleSignIn = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: '30358834933-hrdugbcilc147l0raagusi309365v2td.apps.googleusercontent.com', // Replace with your Google Client ID
        callback: handleGoogleSignIn,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('googleSignInButton'),
        { 
          theme: 'outline', 
          size: 'large',
          width: '100%',
          height: 50,
          longtitle: true,
          text: 'signin_with',
          shape: 'pill',
        }
      );

      setGoogleLoaded(true);
    }
  };

  const handleGoogleSignIn = async (response) => {
    
    try {
      const result = await apiClient().post('/auth/google', {
        token: response.credential
      });
  
      dispatch({ type: LOGIN_CLIENT, payload: result?.data?.data?.user });
  
      if (result.data.status) {
        toast.success("Connexion réussie avec Google !", {
          icon: <CheckCircle className="text-green-500" />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        navigate("/home");
      } else {
        toast.error(result.data.message || "Échec de la connexion avec Google", {
          icon: <AlertCircle className="text-red-500" />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la connexion avec Google", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({ ...prev, login: true }));
    
    if(localStorage.getItem('type_push')){
      if(localStorage.getItem('typepush')=='ios'){
        formData.push_ios=localStorage.getItem('token_push');
      }
      else{
        formData.push_android=localStorage.getItem('token_push');
      }
    }

    try {
      const response = await dispatch(loginClient(formData));
      if (response.success) {
        toast.success("Connexion réussie !", {
          icon: <CheckCircle className="text-green-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
        navigate("/");
      } else {
        toast.error("Échec de connexion: " + response.message, {
          icon: <AlertCircle className="text-red-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, login: false }));
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({ ...prev, forgotPassword: true }));

    try {
      const response = await apiClient().post("/forgot-password", {
        email: forgotPasswordEmail,
      });
      if (response?.status === 200) {
        toast.success("Un e-mail de réinitialisation a été envoyé.", {
          icon: <CheckCircle className="text-green-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
        setResetStep("enterCode");
      } else {
        toast.error("E-mail non trouvé", {
          icon: <AlertCircle className="text-red-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, forgotPassword: false }));
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      apiClient()
        .post("/check-code", {
          email: forgotPasswordEmail,
          resetNumber: codeVerification,
        })
        .then((response) => {
          if (response?.data?.status) {
            toast.success("Code vérifié avec succès !", {
              icon: <CheckCircle className="text-green-500" />,
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setResetStep("newPassword");
          } else {
            toast.error("Le code de réinitialisation est incorrect", {
              icon: <AlertCircle className="text-red-500" />,
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setResetStep("enterCode");
          }
        });
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      console.error(error);
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPass) {
      toast.error("Les mots de passe ne correspondent pas !", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
      return;
    }

    setIsLoading((prev) => ({ ...prev, resetPassword: true }));

    try {
      const response = await apiClient().post("/reset-password", {
        email: forgotPasswordEmail,
        password: formData.password,
      });
      if (response.data.status) {
        toast.success("Mot de passe réinitialisé avec succès !", {
          icon: <CheckCircle className="text-green-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
        setFormData({ ...formData, password: "" })
        setResetStep("login");
      } else {
        toast.error("Échec de la réinitialisation", {
          icon: <AlertCircle className="text-red-500" />,
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.", {
        icon: <AlertCircle className="text-red-500" />,
        style: { borderRadius: "10px", background: "#333", color: "#fff" },
      });
    } finally {
      setIsLoading((prev) => ({ ...prev, resetPassword: false }));
    }
  };

  const renderForm = () => {
    switch (resetStep) {
      case "login":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center px-4">
            <h2 className="text-2xl font-bold mb-8 text-center text-white">
              Connexion
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                  value={formData.email || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
                <Mail
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                  size={22}
                />
              </div>

              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Mot de passe"
                  className="w-full py-4 pl-12 pr-12 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                  value={formData.password || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required
                />
                <Lock
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                  size={22}
                />
                <button
                  type="button"
                  className="absolute right-4 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="text-white" size={24} />
                  ) : (
                    <Eye className="text-white" size={24} />
                  )}
                </button>
              </div>

              <div className="text-right">
                <button
                  type="button"
                  className="text-white font-semibold"
                  onClick={() => setResetStep("forgotPassword")}
                >
                  Mot de passe oublié ?
                </button>
              </div>

              

              <button
                type="submit"
                disabled={isLoading.login}
                className={`w-full bg-white text-[#fd8000] py-4 rounded-xl uppercase font-bold ${
                  isLoading.login
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-white/90"
                } transition duration-300 flex items-center justify-center`}
              >
                {isLoading.login ? (
                  <Loader2 className="animate-spin" size={24} />
                ) : (
                  "Connexion"
                )}
              </button>
            </form>

            <div className="mt-4 mb-4">
              <div className="relative">
                
                <div className="flex items-center justify-center">
                <div className="w-1/4 h-px bg-white/30"></div>
                <span className="mx-4 text-white/70">Ou</span>
                <div className="w-1/4 h-px bg-white/30"></div>
              </div>
              </div>
            </div>

            <div id="googleSignInButton" className="w-full mb-4"></div>

            <div className="text-center">
              {/* <div className="flex items-center justify-center">
                <div className="w-1/4 h-px bg-white/30"></div>
                <span className="mx-4 text-white/70">Ou</span>
                <div className="w-1/4 h-px bg-white/30"></div>
              </div> */}
              <div className="mt-4 text-white"> 
                Vous n'avez pas encore de compte ?
                <button
                  onClick={() => navigate("/register")}
                  className="ml-2 font-semibold"
                >
                  Créer un compte
                </button>
              </div>
            </div>
          </div>
        );

      case "forgotPassword":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center px-4">
            <h2 className="text-2xl font-bold mb-8 text-center text-white">
              Mot de passe oublié
            </h2>
            <form onSubmit={handleForgotPassword} className="space-y-4">
              <div className="relative">
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                  value={forgotPasswordEmail || ""}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  required
                />
                <Mail
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                  size={22}
                />
              </div>
              <button
                type="submit"
                disabled={isLoading.forgotPassword}
                className={`w-full bg-white text-[#fd8000] py-4 rounded-xl uppercase font-bold ${
                  isLoading.forgotPassword
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-white/90"
                } transition duration-300 flex items-center justify-center`}
              >
                {isLoading.forgotPassword ? (
                  <Loader2 className="animate-spin" size={24} />
                ) : (
                  "Envoyer l'email"
                )}
              </button>
            </form>
            <div className="mt-6 text-center">
              <button
                onClick={() => setResetStep("login")}
                className="text-white font-semibold"
              >
                Retour à la connexion
              </button>
            </div>
          </div>
        );

      case "enterCode":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center px-4">
            <h2 className="text-2xl font-bold mb-8 text-center text-white">
              Réinitialisation du mot de passe
            </h2>
            <form onSubmit={handleVerifyCode} className="space-y-4">
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Entrez le code"
                  id="code"
                  className="w-full py-4 pl-12 pr-12 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                  value={codeVerification}
                  onChange={(e) => setCodeVerification(e.target.value)}
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full text-[#fd8000] bg-white py-3 rounded-lg hover:bg-orange-600 font-bold uppercase transition duration-300"
              >
                Vérifier
              </button>
            </form>
          </div>
        );

      case "newPassword":
        return (
          <div className="min-h-screen bg-[#fd8000] flex flex-col justify-center px-4">
          
          <h2 className="text-2xl font-bold mb-8 text-center text-white">
              Nouveau mot de passe
            </h2>
            <form
            onSubmit={handleNewPasswordSubmit}
            className="space-y-4"
          >
            <div className="mb-4 relative">
              <div className="relative">
                <input
                  type={showSPassword ? "text" : "password"}
                  placeholder="Nouveau mot de passe"
                  id="password"
                  className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                  value={formData?.password || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required
                />
                <Lock
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                  size={20}
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowSPassword(!showSPassword)}
                >
                  {showSPassword ? (
                    <EyeOff className="text-white" size={22} />
                  ) : (
                    <Eye className="text-white" size={22} />
                  )}
                </button>
              </div>
            </div>

            <div className="mb-4 relative">
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirmer mot de passe"
                  id="confirmPassword"
                  className="w-full py-4 pl-12 pr-4 text-base bg-white/20 text-white placeholder-white/70 rounded-xl focus:outline-none focus:ring-2 focus:ring-white"
                  value={formData?.confirmPass || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, confirmPass: e.target.value })
                  }
                  required
                />
                <Lock
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white"
                  size={20}
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="text-white" size={20} />
                  ) : (
                    <Eye className="text-white" size={20} />
                  )}
                </button>
              </div>
            </div>

            <button
              type="submit"
              className="w-full text-[#fd8000] uppercase font-bold bg-white py-3 rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Réinitialiser
            </button>
          </form>
          </div>
        );

      default:
        return null;
    }
  };

  return <>{renderForm()} <Toaster
  position="top-center"
  toastOptions={{
    duration: 4000,
    success: {
      style: {
        background: "white",
        color: "black",
      },
    },
    error: {
      style: {
        background: "white",
        color: "black",
      },
    },
  }}
/></>;
}

export default MobileLogin;
