import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skippwhite } from '../../assets/images';


function MobileLogin() {
  const [resetStep, setResetStep] = useState('welcome');
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const platform = queryParams.get('platform');

  useEffect(() => {
    if (token) {
      localStorage.setItem('token_push', token);
      localStorage.setItem('type_push', platform);
      alert(token)
    }
  }, []);

  const renderForm = () => { 
    switch (resetStep) {
      case "welcome":
        return (
          <div className="min-h-screen bg-gradient-to-b from-[#fd8000] to-[#ffab40] flex flex-col justify-center items-center px-4 text-center text-white relative">
            <img 
              src={Skippwhite}
              alt="Logo" 
              className="w-40 h-34 mb-8" 
            />
            <h1 className="text-3xl font-bold mb-4">Recherchez, Réservez, Profitez</h1>
            <p className="text-lg mb-8">
              Profitez d’une expérience fluide pour vos réservations.
            </p>
            <button
              onClick={() => setResetStep("selectAction")}
              className="bg-white text-[#fd8000] py-3 px-8 rounded-xl uppercase font-bold hover:bg-white/90 transition duration-300"
            >
              Commencer
            </button>
          </div>
        );
  
      case "selectAction":
        return (
          <div className="min-h-screen bg-gradient-to-b from-[#fd8000] to-[#ffab40] flex flex-col justify-center items-center px-4 text-center text-white relative">
            {/* Decorative Circles */}
            <div className="absolute top-0 right-0 w-32 h-32 bg-white rounded-full opacity-20"></div>
            <div className="absolute bottom-0 left-0 w-32 h-32 bg-white rounded-full opacity-20"></div>
            
            {/* Logo */}
            <img 
              src={Skippwhite}
              alt="Logo" 
              className="w-40 h-34 mb-8" 
            />
  
            <h2 className="text-2xl font-bold mb-6">Bienvenue !</h2>
            <div className="space-y-4">
              <button
                onClick={() => navigate('/connexion')}
                className="w-60 bg-white text-[#fd8000] py-3 rounded-xl uppercase font-bold hover:bg-white/90 transition duration-300"
              >
                Connexion
              </button>
              <button
                onClick={() => navigate('/register')}
                className="w-60 bg-transparent border-2 border-white text-white py-3 rounded-xl uppercase font-bold hover:bg-white/20 transition duration-300"
              >
                S'inscrire
              </button>
            </div>
          </div>
        );
        
        default:
          return null;
      }
    };

    return (
      <div>
        {renderForm()}
      </div>
    );
  }
  
  export default MobileLogin;