import React, { useEffect } from 'react';
import axios from 'axios';
import { apiClient } from '../../actions/api';
import { useSelector } from 'react-redux';

const UpdateIpAddress = () => {
    const client = useSelector((state) => state?.auth?.client);

  // Function to get IP address using ipify API
  const getIpAddress = async () => {
    try {
        const response = await fetch('http://api.ipify.org/?format=json');
        const data = await response.json();
      return data; // Return the user's IP address
    } catch (error) {
      console.error('Error getting IP address:', error);
      return null;
    }
  };

  // Function to update the user's IP address
  const updateIpAddress = async (ipAddress) => {
    try {
      const response = await apiClient().put(`/user/${client?.id}/update-ip`, {
        ip_address: ipAddress,
      });

      if (response.data.success) {
        console.log('IP address updated successfully');
      } else {
        console.log('Failed to update IP address:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating IP address:', error);
    }
  };

  // Effect to run every 5 minutes
  useEffect(() => {
    const interval = setInterval(async () => {
      getIpAddress().then((data) => {
        updateIpAddress(data?.ip);
      });
    }, 300000); // 300,000ms = 5 minutes

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [client?.id]);

  return null; // This component does not need to render anything
};

export default UpdateIpAddress;