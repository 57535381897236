import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import { Check as CheckIcon, X } from 'lucide-react';

const PaymentSuccessModal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const showModal = searchParams.get('success') === 'true';

  const handleClose = () => {
    navigate('/');
  };

  return (
    <Dialog 
      open={showModal} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <Box position="absolute" right={8} top={8}>
        <IconButton onClick={handleClose} size="small">
          <X size={20} />
        </IconButton>
      </Box>

      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        pt={3}
        px={3}
      >
        <Box 
          sx={{
            backgroundColor: 'success.light',
            borderRadius: '50%',
            width: 64,
            height: 64,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <CheckIcon color="white" size={32} />
        </Box>

        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h5" align="center" fontWeight="bold">
            Paiement Réussi
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography align="center" color="text.secondary" sx={{ mb: 3 }}>
            Votre réservation a été confirmée avec succès. Un email de confirmation vous sera envoyé prochainement.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ pb: 3, px: 3, width: '100%', justifyContent: 'center', gap: 2 }}>
          <Button 
            variant="contained" 
            color="success"
            onClick={handleClose}
            sx={{ px: 4 }}
          >
            D'accord
          </Button>
          <Button 
            variant="outlined"
            color="success"
            onClick={handleClose}
            sx={{ px: 4 }}
          >
            Fermer
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PaymentSuccessModal;